import React from "react";
import logo from "../img/logo.png";
import coming from "../img/coming soon.png";
import baby from "../img/baby.gif";
import { Image } from "cloudinary-react";

import { BsTwitter } from "react-icons/bs";

function ComingSoon() {
  return (
    <div className="px-12 md:px-20 py-10 relative">
      <div className="flex items-center">
        <div className="flex flex-col items-center md:items-start justify-between coming-container">
          <img className="logo" src={logo} alt="" />
          <div className="flex">
            <img className="h-20" src={coming} alt="" />
            <a
              className="mt-12 -ml-32 md:-ml-40 bg-black px-5 py-3 rounded-full  text-white text-2xl flex items-center space-x-2 hover:bg-white hover:text-black"
              href="https://twitter.com/LionLoverboy"
              target="_blank"
            >
              {/* <img
                src={twitter}
                className="h-6 hover:text-black"
                alt="Twitter Image"
              /> */}
              <BsTwitter />
              <span className="">twitter</span>
            </a>
          </div>
        </div>
        <Image
          cloudName="ds4kjgovs"
          publicId="https://res.cloudinary.com/ds4kjgovs/image/upload/v1644687366/mechanimals/baby_ag2adc.gif"
          className="absolute image__baby right-2 mt-20 md:right-10"
        />
        {/* <img className="absolute image__baby right-2 md:right-10" src={baby} /> */}
      </div>
    </div>
  );
}

export default ComingSoon;
